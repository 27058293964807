import { Box, CircularProgress } from '@mui/material';
import { Button, Dialog, SelectField } from 'components/ui';
import { HTTP_METHOD, NPCS_URL, USERS_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Character, User } from 'types';

type Props = {
  open: boolean
  onClose: () => void
  triggerRefetch: () => void
  character: Character
}

export function AssignDialog(props: Readonly<Props>) {
  const [loading, setLoading] = useState<boolean>(true);
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();

  const { data } = useFetch<User[]>(USERS_URL);
  const [users, setUsers] = useState<User[]>();
  const [owner, setOwner] = useState<number>();

  useEffect(() => {
    if (data) {
      setUsers(data);
      setLoading(false);

      const assignee = data?.find((u) => u.id == props.character?.userId);
      setOwner(assignee?.id);
    }
  }, [data]);

  const changeOwner = (event) => {
    setOwner(event.target.value);
  };

  const onSave = () => {
    setLoading(true);
    fetchFromBackend(`${NPCS_URL}/${props.character?.id}/owner?userId=${owner}`, {
      method: HTTP_METHOD.PATCH
    }).then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
          if (response.ok) {
            props.triggerRefetch();
          }

          setLoading(false);
          showSnackbar(`${t('Components.Admin.Character.OwnerUpdate')} ${result}`, severity);
          props.onClose();
        }
      ) 
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={`${t('Components.Admin.Character.AssignFor')} ${props.character?.name}`}
      width='500px'
      content={
        loading ? 
          <Box sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <CircularProgress />
          </Box>
        :
          <Box>
            <SelectField
              numbered 
              label={''} 
              id={''} 
              numberedList={users}
              numberedValue={owner}
              onChange={changeOwner}
            />
          </Box>
      }
      actions={
        <Button 
          color="success"
          onClick={onSave} 
          text={t('Generic.Save')}
        />
      }
    />
  );
}
