import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

type Props = {
  i18nText: string,
  image?: string
}
export function DraperyCheckStep(props: Readonly<Props>): ReactNode {
  return (
    <Box sx={{padding: '10px 0 0 20px'}}>
      <Typography
        variant="body1"
        component="p"
        sx={{
          padding: '10px 0 10px 0',
          fontFamily: 'Neuton',
          color: 'rgba(255, 255, 255, 0.6)',
          fontSize: '1.4rem',
        }}
      >
        <Trans i18nKey={props.i18nText} />
      </Typography>
      {props.image && <Box
        component="img"
        sx={{
          width: '100%',
          boxShadow: '10px 10px 10px #1e1e1e'
        }}
        src={props.image}
        alt="DraperyCheck Step2"
      />}
    </Box>
  );
}