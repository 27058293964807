import { Alert, AlertColor, Snackbar } from '@mui/material';
import { SNACKBAR_AUTO_HIDE } from 'const';
import { SnackbarContext } from 'contexts';
import { useState, FC, ReactNode, useMemo } from 'react';

export const SnackbarProvider: FC<{children: ReactNode}> = ({children}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('info');
  const [autoHide, setAutoHide] = useState<number>(SNACKBAR_AUTO_HIDE);

  const showSnackbar = (message: string, severity: AlertColor, autoHide: number=SNACKBAR_AUTO_HIDE) => {
    setMessage(message);
    setSeverity(severity);
    setAutoHide(autoHide);
    setOpen(true);
  };

  const snackbarContextValue = useMemo(() => ({ showSnackbar }), [open, message, severity]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={snackbarContextValue}>
      {children}
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open} 
        autoHideDuration={autoHide} 
        onClose={onClose} 
      >
        <Alert variant="filled" severity={severity} onClose={onClose} sx={{ width: '100%', fontSize: '1rem' }}>
            {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};