
import {useContext, ReactNode} from 'react';
import {Box, Grid} from '@mui/material';
import { t } from 'i18next';

import { CardSectionTitle, TextField } from 'components/ui';

import { CharacterContext } from 'contexts';

type Props = {
  readonly: boolean
}

export function Interests(props: Readonly<Props>) : ReactNode {
    const {char, setChar} = useContext(CharacterContext);

    return (
        <Box
            sx={{
                marginTop: 0,
                marginLeft: 0,
                paddingBottom: '30px'
            }}
        >
            <CardSectionTitle id="Generic.Interests" />
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={6}>
                    <TextField
                        readonly={props.readonly}
                        multiline
                        id="interests"
                        defaultValue={char?.interests}
                        label={t('Components.PlayerCharacters.Interests')}
                        onChange={(event) => setChar({...char, interests: event.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        readonly={props.readonly}
                        multiline
                        id="antipathies"
                        defaultValue={char?.antipathies}
                        label={t('Components.PlayerCharacters.Antipathies')}
                        onChange={(event) => setChar({...char, antipathies: event.target.value})}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
