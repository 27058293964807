import { useMemo, useEffect, useState, ReactNode, useContext } from 'react';
import Carousel from 'react-material-ui-carousel';

import { CardCircularProgress } from 'components/thestral';

import { CharacterCardItem } from './CharacterCardItem';
import { NewCharacter } from './NewCharacter';

import { Character } from 'types';
import { useFetch } from 'func';
import { CharacterContext, UserContext } from 'contexts';
import { PROFILES_URL } from 'const';

export function CharacterCard(): ReactNode {
  const {user} = useContext(UserContext);
  const {data, loading, triggerRefetch} = useFetch<Character[]>(`${PROFILES_URL}/${user?.profile?.id}/characters`);
  const [char, setChar] = useState<Character>(undefined);
  const [characters, setCharacters] = useState<Character[]>();
  const charContextValue = useMemo(() => ({ char, setChar }), [char, setChar]);

  useEffect(() => {
    if (data) {
      const sorted = sortedChars();
      setChar(sorted[0]);
      setCharacters(sorted);
    }

  }, [data]);

  function sortedChars() {
    return data?.sort((a, b) => {
      if (a.npcType == null && b.npcType != null) return -1;
      if (a.npcType != null && b.npcType == null) return 1;
      return a.name.localeCompare(b.name);
    });
  }

  return (
    <>
      {loading ?
        <CardCircularProgress />
        :
        <CharacterContext.Provider value={charContextValue}>
        { characters?.length == 0 &&
          <NewCharacter />
        }
        { characters?.length > 0 &&
          <Carousel
            autoPlay={false}
            animation="slide"
            onChange={(i) => setChar(characters[i])}
            height="336px"
          >

            {
              sortedChars().map((item: Character) =>
                <CharacterCardItem
                  key={item.id} fetchChars={() => triggerRefetch()}
                />
              )
            }
          </Carousel>
        }
        </CharacterContext.Provider>
      }
    </>
  );
}
