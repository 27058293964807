import { ReactNode, useContext } from 'react';
import { Box, Grid } from '@mui/material';

import { CardSectionTitle } from 'components/ui';

import { ActiveSince } from './ActiveSince';
import { FounderHouse } from './FounderHouse';
import { ExternalInfo } from './ExternalInfo';
import { TeacherSubject } from './TeacherSubject';
import { PortraitAuthor } from './PortraitAuthor';

import { CharacterContext } from 'contexts';

type Props = {
  readonly: boolean
}

export function Special(props: Readonly<Props>) : ReactNode {
  const {char} = useContext(CharacterContext);

  return (
    <Box sx={{ paddingTop: '20px' }}>
      <CardSectionTitle id="Generic.Special" />

      <Grid container spacing={2}>
      {['TEACHER', 'STAFF', 'GHOST', 'PORTRAIT'].includes(char.npcType) &&
        <ActiveSince readonly={props.readonly} />
      }
      {['FOUNDER'].includes(char.npcType) && char?.house != 'NOHOUSE' &&
        <FounderHouse readonly={props.readonly} />
      }
      {['EXTERNAL'].includes(char.npcType) &&
        <ExternalInfo readonly={props.readonly} />
      }
      {['TEACHER'].includes(char.npcType) &&
        <TeacherSubject readonly={props.readonly} />
      }
      {['PORTRAIT'].includes(char.npcType) &&
        <PortraitAuthor readonly={props.readonly} />
      }
      </Grid>
    </Box>
  );
}