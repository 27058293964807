import { Trans } from 'react-i18next';
import { Box, Grid, IconButton, Link } from '@mui/material';
import { DateTime } from 'luxon';
import { LinkText } from 'components/ui';
import { Instagram, X } from '@mui/icons-material';

export function Footer() {
  const currentYear = () => {
    return DateTime.now().year;
  };

  return (
    <Box 
      sx={{
        borderTop: '1px solid white',
        backgroundColor: 'transparent',
        margin: '20px 20px 0 20px',
        color: 'white',
        flexShrink: 0,
        fontSize: 'large',
        fontFamily: 'Bebas',
        left: '20px',
        right: '20px',
        height: '65px',
        bottom: 0,
      }}
    >
      <Grid container direction="row">
        <Grid item xs={4} sx={{ padding: '20px' }}>
          {currentYear()} | <Trans i18nKey="Generic.Title" /> | {process.env.REACT_APP_VERSION}
        </Grid>
        <Grid item xs={4} sx={{ padding: '20px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <Link target="_blank" href="//instagram.com/thestrallarp">
              <IconButton sx={{ padding: '5px' }}>
                <Instagram sx={{ color: '#ffffff' }} />
              </IconButton>
            </Link>
            <Link target="_blank" href="//x.com/thestrallarp">
              <IconButton sx={{ padding: '5px' }}>
                <X sx={{ color: '#ffffff' }} />
              </IconButton>
            </Link>
        </Grid>
        <Grid item xs={4} sx={{ padding: '20px', textAlign: 'right' }}>
            <LinkText to={'/imprint'} underline='none' color="#ffffff">
              <Trans i18nKey="Components.Imprint.Title" />
            </LinkText>
            |
            <LinkText to={'/privacy'} underline='none' color="#ffffff">
              <Trans i18nKey="Components.Privacy.Title" />
            </LinkText>
        </Grid>
      </Grid>
    </Box>
  );
}
