import { ReactNode } from 'react';
import { Typography } from '@mui/material';

type  Props = {
  title: string
}

export function GridHeadline(props: Readonly<Props>): ReactNode {
  return (
    <Typography variant="ruleHeadline" component="p">
      {props.title}
    </Typography>
  );
}
