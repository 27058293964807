import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Box, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import { Logout as LogoutIcon, PowerSettingsNew as PowerSettingsNewIcon } from '@mui/icons-material';

import { BugReportMenuItem } from './BugReportMenuItem';

export function LogoutMenu() {
  const nav = useNavigate();
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  function signout() {
    auth.signoutSilent();
    nav('/login');
  }

  return (
    <Box sx={{ marginLeft: 'auto' }}>
      <IconButton
        size="large"
        aria-controls="profile-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={open}
      >
        <PowerSettingsNewIcon />
      </IconButton>

      <Menu 
        id="profile-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={close}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: '#1f1f1f',
          }
        }}
      >
        <BugReportMenuItem />
        <MenuItem onClick={signout}>
          <LogoutIcon sx={{ marginRight: '32px' }} />
          <Typography variant="h6" component="span" className="font-bebas">
            <Trans i18nKey="Generic.Logout" />
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}