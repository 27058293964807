import { ReactNode } from 'react';
import { Box } from '@mui/material';

import { RulesListItem } from '../RulesListItem';

type Props = {
  items: string[]
}

export function RulesBlock(props: Readonly<Props>): ReactNode {
  return (
    <Box>
      {
        props.items.map((item) =>
          <RulesListItem key={item} text={item} />
        )
      }
    </Box>
  );
}