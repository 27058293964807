import { ReactNode } from 'react';
import { Typography } from '@mui/material';

type Props = {
  headline: string
}

export function ThestralDataGridHeadline(props: Readonly<Props>): ReactNode {
  return (
    <Typography 
      component="p" 
      sx={{ 
        fontFamily: 'Neuton', 
        fontSize: '1.25rem', 
        textAlign: 'right', 
        fontWeight: '700', 
        margin: '50px 0 0 0', 
        color: 'primary.main', 
        letterSpacing: '0.0125em' 
      }}>
      {props.headline}
    </Typography>
  );
}