import { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, LinearProgress } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon';
import { EventImpl } from '@fullcalendar/core/internal';
import { DateTime } from 'luxon';
import { t } from 'i18next';

import { Button, DateTimeField, Dialog, TextField } from 'components/ui';

import { deleteEvent, getCalendarEvents, updateEvent } from 'calendar';

export function AdminCalendar() {
  const [loading, setLoading] = useState<boolean>(true);
  const [events, setEvents] = useState(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [event, setEvent] = useState<EventImpl>();

  useEffect(() => {
    if (!events) {
      const fetchEvents = async () => {
        const events = await getCalendarEvents();
        setEvents(events);
        setLoading(false);
      };
      fetchEvents();
    }
  }, []);

  const handleClick = (evt) => {
    setEvent(evt.event);
    setOpen(true);
  };

  const handleRemove = async () => {
    setLoading(true);
    if (!event.extendedProps['cancel']) {
      await deleteEvent(event).then(() => {
        setOpen(false);
      });
    }
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    await updateEvent(event).then(() => {
      setLoading(false);
      setOpen(false);
    });
  };

  const handleDrop = async (evt) => {
    setLoading(true);
    await updateEvent(evt.event).then(() => {
      setLoading(false);
    });
  };

  const handleSelect = (evt) => {
    const calendarApi = evt.view.calendar;
    calendarApi.unselect();
    const newEvent = calendarApi.addEvent({
      id: crypto.randomUUID(),
      title: '',
      start: DateTime.fromISO(evt.startStr).toJSDate(),
      end: DateTime.fromISO(evt.startStr).toJSDate(),
      extendedProps: {
        new: true
      }
    });

    setEvent(newEvent);
    setOpen(true);
  };

  const handleChange = (evt) => {
    if (evt.event.extendedProps['cancel'] && evt.event.extendedProps['new']) {
      evt.event.remove();    
    } else {
      setEvent(evt.event);
    }
  };

  const onDelete = () => {
    event.remove();
  };

  const onClose = () => {
    event.setExtendedProp('cancel', true);
    setOpen(false);
  };

  return (
    <Box className="content" sx={{margin: '75px auto 0 auto', maxWidth: '100%'}}>
      {loading && <LinearProgress color="secondary" />}

      <Box 
        sx={{
          width: '100%',
          marginTop: '50px',
          '& .fc' : {
            fontSize: '1.5rem'
          },
          '& .fc-daygrid-event-dot' :{
            borderColor: 'secondary.main',
            marginTop: '10px'
          },
          '& .fc-col-header': {
            color: 'primary.main'
          },
          '& .fc-daygrid-day-number': {
            color: 'primary.main'
          },
          '& .fc-daygrid-event': {
            whiteSpace: 'normal !important',
            alignItems: 'normal !important'
          },
          '& .fc-toolbar-title': {
            color: 'primary.main'
          },
          '& .fc-event-title': {
            color: '#fff'
          },
          '& .fc-event-time': {
            color: '#fff'
          },
          '& .fc-button': {
            backgroundColor: 'secondary.main'
          },
          '& .fc-button:disabled': {
            backgroundColor: 'secondary.main'
          },
          '& .fc-h-event': {
            backgroundColor: 'secondary.main',
            border: 0
          },
          '.fc-daygrid-block-event .fc-event-time': {
            display: 'none'
          }
        }}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, luxonPlugin]}
          initialView="dayGridMonth"
          events={events}
          locale="de"
          themeSystem='standard'
          firstDay={1}
          height="100vh"
          expandRows
          buttonText={{
            today: t('Generic.Today')
          }}
          select={handleSelect}
          eventClick={handleClick}
          eventRemove={handleRemove}
          eventDrop={handleDrop}
          eventChange={handleChange}
          selectable
          editable
        />
      </Box>

      <Dialog 
        key={event?.title}
        open={open}
        onClose={onClose}
        title={event?.title}
        width="600px"
        content={loading ? 
          <Box sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <CircularProgress /> 
          </Box>
            : 
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField 
                defaultValue={event?.title}
                onChange={(evt) => event.setProp('title', evt.target.value)}
                label={t('Components.Admin.Calendar.Dialog.Title')}
              /> 
            </Grid>
            <Grid item xs={6}>
              <DateTimeField 
                value={event?.start}
                label={t('Components.Admin.Calendar.Dialog.From')}
                onChange={(newStart) => event.setDates(newStart.toJSDate(), event?.end)}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimeField 
                value={event?.end}
                label={t('Components.Admin.Calendar.Dialog.To')}
                onChange={(newEnd) => event.setDates(event?.start, newEnd.toJSDate())}
              />
            </Grid>
          </Grid>
        }
        actions={
          <>
            <Button color="success" text={t('Generic.Save')} onClick={handleSave} disabled={loading} />
            <Button color="error" text={t('Generic.Delete')} onClick={onDelete} disabled={loading} />
          </>
        }
      /> 
    </Box>
  );
}
