import { ReactNode } from 'react';
import { RadioGroup, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { FormControlLabel } from 'components/ui';
import { FormikProps, FormikValues } from 'formik';

type Props = {
  formik: FormikProps<FormikValues>
}

export function ImageRights(props: Readonly<Props>): ReactNode {
  const formik = props.formik;

  return (
    <>
      <Typography 
        id="radio-image-rights"
        variant="ruleTitle" 
        component="p" 
      >
        <Trans i18nKey="Components.Profile.DataProtectionData.ImageRights.Title" />
      </Typography>

      <RadioGroup
        id="imageRights"
        aria-labelledby='radio-image-rights'
        name="radio-button-group-image-rights"
        value={formik.values.profile?.imageRights}
        onChange={(event) => formik.setFieldValue('profile.imageRights', (!formik.values.readonly ? event.target.value : formik.values.profile?.imageRights))}
      >
        <FormControlLabel value="NODISCLOSURE" label={t('Components.Profile.DataProtectionData.ImageRights.NoDisclosure')} />
        <FormControlLabel value="NOTAGGING" label={t('Components.Profile.DataProtectionData.ImageRights.NoTagging')} />
        <FormControlLabel value="TAGME" label={t('Components.Profile.DataProtectionData.ImageRights.TagMe')} />
      </RadioGroup>
    </>
  );
}
