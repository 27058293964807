import { Box, Pagination, PaginationItem } from '@mui/material';
import { gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';

export function DataGridPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Box>
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        renderItem={(props) => <PaginationItem {...props} sx={{color: '#fff'}}/>}
        onChange={(event, value) => apiRef.current.setPage(value -1)}
      />
    </Box>
  );
}