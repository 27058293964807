import { useContext, ReactNode } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';

import { CharacterContext } from 'contexts';
import { TextField } from 'components/ui';

type Props = {
  readonly: boolean
}

export function ExternalInfo(props: Readonly<Props>) : ReactNode {
  const {char, setChar} = useContext(CharacterContext);

  return (
    <>
      <Grid item xs={4}>
        <TextField 
          id="char.employer"
          readonly={props.readonly}
          defaultValue={char.employer}
          label={t('Components.NonPlayerCharacters.External.Employer')}
          onChange={(event) => setChar({...char, employer: event.target.value})}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField 
          id="char.department"
          readonly={props.readonly}
          defaultValue={char.department}
          label={t('Components.NonPlayerCharacters.External.Department')}
          onChange={(event) => setChar({...char, department: event.target.value})}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField 
          id="char.occupation"
          readonly={props.readonly}
          defaultValue={char.occupation}
          label={t('Components.NonPlayerCharacters.External.Occupation')}
          onChange={(event) => setChar({...char, occupation: event.target.value})}
        />
      </Grid>
    </>
  );
}