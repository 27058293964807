import { useContext, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { t } from 'i18next';
import { useAuth } from 'react-oidc-context';

import { Greeting } from './Greeting';
import { Card } from './Card';
import { Sections } from './Sections';
import { CharacterCard } from './Card/CharacterCard';
import { EventCard } from './Card/EventCard';

import { useFetch } from 'func';
import { User } from 'types';
import { UserContext } from 'contexts';
import { USERS_URL } from 'const';

export function LoggedIn() {
  const auth = useAuth();
  const email = auth.user?.profile.email;

  const {data} = useFetch<User>(`${USERS_URL}/by-email/${email}`);
  const {user, setUser} = useContext(UserContext);

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  return (
    <Box sx={{flex: 1}}>
      <Greeting nickname={user?.profile?.nickname} />

      <Grid container spacing={2}>
        <Grid item xs={4}>
          {user?.id != undefined &&
          <Card title={t('Components.LoggedIn.CardTitle.Character')} content={<CharacterCard />} />
          }
        </Grid> 
        <Grid item xs={4}>
        </Grid> 
        <Grid item xs={4}>
          {user?.id != undefined &&
          <Card title={t('Components.LoggedIn.CardTitle.Event')} content={<EventCard />} />
          }
        </Grid> 

        <Grid item xs={12}>
          <Sections />
        </Grid>
      </Grid>
    </Box>
  );
}