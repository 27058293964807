import { ReactNode, useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { AccountPlus } from 'mdi-material-ui';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { Button, Dialog } from 'components/ui';
import { PlayerCharacterCard } from 'components/thestral';

import { CHARACTERS_URL, HTTP_METHOD, USERS_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { User } from 'types';
import { UserContext, CharacterContext } from 'contexts';

export function NewCharacter() : ReactNode {
  const fetchFromBackend = useFetchFromBackend();
  const auth = useAuth();
  const email = auth.user?.profile.email;

  const { data, triggerRefetch } = useFetch<User>(`${USERS_URL}/by-email/${email}`);

  const {user, setUser} = useContext(UserContext);
  const {char, setChar} = useContext(CharacterContext);
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

 function openDialog() {
    setChar({profileId: user?.profile?.id, new: true});
    setOpen(true);
  }

  function content(): ReactNode {
    return (
      <PlayerCharacterCard />
    );
  }

  function actions(): ReactNode {
    return (
      <Button onClick={saveCharacter} color="success" text={<Trans i18nKey="Generic.Save" />} />
    );
  }

  function saveCharacter() {
    const body = JSON.stringify({...char, profileId: user?.profile.id});
    fetchFromBackend(CHARACTERS_URL, {method: HTTP_METHOD.POST, body})
      .then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;
          if (response.ok) {
            triggerRefetch();
            setChar({...char, new: false});
          }

          showSnackbar(`${t('Components.Characters.NewSaved')} ${result}`,severity);
      })
      .catch((error) => console.error(error));

    setOpen(false);
  }

  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '60%'
      }}  
    >
      <Button 
        sx={{ 
          display: 'block',
          backgroundColor: 'primary.main',
          ':hover': {
            borderRadius: '5%',
            backgroundColor: 'secondary.main'
          },
          '& .MuiButtonBase-root:hover': {
            backgroundColor: 'secondary.main'
          },
          '& .MuiSvgIcon-root': {
            height: '3em',
            width: '3em',
            color: '#1b1b1b'
          },
        }}
        onClick={openDialog}
        text={
          <>
            <AccountPlus />
            <Typography 
              variant="h2" 
              sx={{ 
                fontFamily: 'Bebas',
                fontSize: '18pt', 
                color: '#1b1b1b'
              }}
            >
              {t('Generic.NewChar')}
            </Typography>
          </>
        }
      />
      {open && <Dialog 
        sx={{
          '& .MuiPaper-root': {
            bgcolor: '#1e1e1e',
            marginBottom: '0'
          }
        }}
        open={open}
        onClose={() => setOpen(false)}
        title={t('Components.PlayerCharacters.New')} 
        content={content()}
        actions={actions()}
      />}
    </Box>
  );
}