import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';

type Props = {
  nickname: string
}

export function Greeting(props: Readonly<Props>): ReactNode {
  return (
    <Box>
      <Typography 
        variant="h1" 
        sx={{ 
          fontSize: '4rem',
          fontWeight: '700', 
          lineHeight: '60px', 
          marginBottom: '50px',
        }}
      >
        <Trans i18nKey='Components.Greeting.Hello' />
          &nbsp;
          <Typography 
            component="span" 
            variant="h1"
            sx={{ 
              color: 'white !important',
              fontSize: 'inherit',
              fontWeight: 'inherit',
              lineHeight: 'inherit'
            }}>
              { props.nickname }
            </Typography> 
          <br />
        <Trans i18nKey='Components.Greeting.Welcome' />
      </Typography>
    </Box>
  );
}
