import { ReactNode } from 'react';
import { useAuth } from 'react-oidc-context';
import { Box, Divider, List } from '@mui/material';
import { Checklist, ContentCut, HowToReg, Shield } from '@mui/icons-material';
import { t } from 'i18next';

import { MenuItem } from 'components/ui';

import { Roles } from 'types';

type Props = {
  onClick: () => void
}

export function AdminItems(props: Readonly<Props>): ReactNode {
  const auth = useAuth();

  function hasAnyRole(allowed: Array<string>) {
    if (auth.isAuthenticated) {
      const roles: Roles = auth.user.profile.roles as Roles;
      return roles?.some(r => allowed.includes(r)) ?? false;
    }

    return true;
  }

  return (
    <Box sx={{ width: '256px', bottom: 0, position: 'absolute' }} >
      <List>
        <Divider sx= {{ borderColor: '#ffffff1f', marginBottom: '3px'}} />
        { hasAnyRole(['PALADIN']) && <MenuItem icon={<Checklist />} text={t('Components.Signup.Approvals.TitleShort')} to="/approval/signups" onClick={props.onClick} /> }
        { hasAnyRole(['APPROVER']) && <MenuItem icon={<HowToReg />} text={t('Components.Characters.Approvals.TitleShort')} to="/approval/characters" onClick={props.onClick} /> }
        { hasAnyRole(['DRAPERY']) && <MenuItem icon={<ContentCut />} text={t('Components.Drapery.Approvals.TitleShort')} to="/approval/drapery" onClick={props.onClick}/> }
        { hasAnyRole(['ADMIN']) && <MenuItem icon={<Shield />} text={t('Components.Admin.Title')} to="/admin" onClick={props.onClick} /> }
      </List>
    </Box>
  );
}
