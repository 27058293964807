import { ReactNode } from 'react';

import { DialogContentText } from '@mui/material';
import { Trans } from 'react-i18next';

import { Button, Dialog } from 'components/ui';
import { t } from 'i18next';

type Props = {
  open: boolean,
  cancel: () => void,
  save: () => void
}

export function CloseConfirm(props: Readonly<Props>): ReactNode {
  return (
    <Dialog 
      open={props.open}
      actions={<Button onClick={props.save} color="success" text={t('Generic.SaveAndClose')} />}
      content={
        <DialogContentText sx={{color: '#fff', fontFamily: 'Neuton'}}>
          <Trans i18nKey="Generic.EditFound" />
        </DialogContentText>
      }
      title={t('Generic.EditFoundTitle')}
      onClose={props.cancel}
    />
  );
}