import { useState, useContext, ReactNode, useEffect, useMemo} from 'react';
import { usePrevious } from 'react-use';
import { t } from 'i18next';
import { useAuth } from 'react-oidc-context';
import { Grid, Box, IconButton, CircularProgress } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { JsonEditor as Editor }  from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

import { TabHeadline, TabContent } from 'components/thestral';
import { Dialog } from 'components/ui';

import { Roles, ThestralContent } from 'types';
import { CONTENT_URL, HTTP_METHOD } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { AccordionContext, JsonContext } from 'contexts';

type Props = {
  ruleType: string,
  slug: string,
  content?: ReactNode
  publicLoc?: boolean
}

export function RulesTab(props: Readonly<Props>): ReactNode {
  const auth = useAuth();

  const i18nKey = props.slug.charAt(0).toUpperCase() + props.slug.slice(1);
  const title = t('Components.Rules.'+props.ruleType+'.'+i18nKey);
  const contentFetch = useFetch<ThestralContent>(`${CONTENT_URL}/slug/${props.publicLoc ? 'public/' : '' }${props.slug}`);
  const fetchFromBackend = useFetchFromBackend();

  const [openEdit, setOpenEdit] = useState(false);
  const { showSnackbar } = useSnackbar();

  const [expanded, setExpanded] = useState<string | false>(false);
  const accordionContextValue = useMemo(() => ({ expanded, setExpanded }), [expanded, setExpanded]);

  const {json, setJson} = useContext(JsonContext);
  const prevJson = usePrevious(json);
  const [id, setId] = useState(-1);

  useEffect(() => {
    if (!contentFetch.loading && contentFetch.data?.content) {
      setJson(JSON.parse(contentFetch.data?.content));
      setId(contentFetch.data?.id);
    }
  }, [props.slug, auth, auth.user?.access_token, contentFetch.loading]);

  const isAdmin = () => {
    if (auth.isAuthenticated) {
      const roles: Roles = auth.user.profile.roles as Roles;
      return roles?.some(r => ['ADMIN'].includes(r)) ?? false;
    } 
    return false;
  };

  const onSave = () => {
    if (json !== prevJson) {
      const body = JSON.stringify({'title': props.slug, content: JSON.stringify(json)});
      fetchFromBackend(`${CONTENT_URL}/${id}`, {method: HTTP_METHOD.PUT, body})
        .then((response) => {
            const severity = response.status < 400 ? 'success' : 'error';
            const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;

            if (response.ok) {
              contentFetch.triggerRefetch();
            }
            showSnackbar(`${t('Json.Drapery', {tab: title})} ${result}`, severity);
          }
        ) 
        .catch((error) => console.error(error));
    }
    setOpenEdit(false);
  };

  function content(): ReactNode {
    return (
      <Editor value={json} onChange={(newJson) => setJson(newJson)} />
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={isAdmin() ? 6 : 12}>
          <TabHeadline title={title} />
        </Grid>
        {isAdmin() &&
        <Grid item xs={6}>
          <IconButton aria-label="edit" onClick={() => setOpenEdit(true)} sx={{float: 'right'}}>
            <Edit sx={{color: 'primary.main'}} />
          </IconButton>
        </Grid>
        }
      </Grid>
      {openEdit && <Dialog
        open={openEdit}
        onClose={onSave}
        title={t('Generic.Edit')}
        content={content()}
        sx={{
          '.jsoneditor': {height: '750px'}
        }}
      />}

      {contentFetch.loading ?
        <CircularProgress />      
      :
      <Box sx={{ padding: '12px'}}>
        {json &&
          <AccordionContext.Provider value={accordionContextValue}>
            {
              Object.keys(json).map((key) => {
                return (<TabContent name={key} key={key}/>);
              })
            }
          </AccordionContext.Provider>
        }
      </Box>
      }
    </>
  );
}