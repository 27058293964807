import { ReactNode } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';

import { DateField, TextField } from 'components/ui';

import { FormikErrors, FormikProps, FormikValues } from 'formik';

type Props = {
  formik: FormikProps<FormikValues>
}

export function ProfileData(props: Readonly<Props>): ReactNode {
  const formik = props.formik;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField 
          name="profile.nickname" 
          label={t('Generic.Nickname')} 
          defaultValue={formik.values.profile?.nickname}
          value={formik.values.profile?.nickname}
          onChange={formik.handleChange}
          error={Boolean((formik.errors.profile as FormikErrors<{ nickname: string }>)?.nickname)}
          helperText={(formik.errors.profile as FormikErrors<{ nickname: string }>)?.nickname}
          readonly={formik.values.readonly}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField 
          name="profile.pronouns" 
          label={t('Generic.Pronouns')} 
          defaultValue={formik.values.profile?.pronouns}
          value={formik.values.profile?.pronouns}
          onChange={formik.handleChange}
          error={Boolean((formik.errors.profile as FormikErrors<{ pronouns: string }>)?.pronouns)}
          helperText={(formik.errors.profile as FormikErrors<{ pronouns: string }>)?.pronouns}
          readonly={formik.values.readonly}
        />
      </Grid>
      <Grid item xs={4} sx={{alignContent: 'flex-end'}}>
        <DateField 
          label={t('Generic.DateOfBirth')} 
          value={formik.values.dateOfBirth} 
          onChange={(value) => formik.setFieldValue('dateOfBirth', value.toISODate())} 
          minDate="1970-01-01" 
          error={Boolean(formik.errors.dateOfBirth)}
          helperText={formik.errors.dateOfBirth as string}
          disableFuture 
          readonly={formik.values.readonly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField 
          name="user.email" 
          label={t('Generic.Email')} 
          defaultValue={formik.values.email}
          value={formik.values.email}
          onChange={(event) => formik.setFieldValue('email', event.target.value)}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email as string}
          readonly={formik.values.readonly}
        />
      </Grid>
    </Grid>
  );
}
