import { ChangeEvent, useEffect, useContext, useState, ReactNode } from 'react';
import { SelectChangeEvent, Box, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { useAuth } from 'react-oidc-context';

import NoPortrait from 'assets/images/no_portrait.png';

import { Button, CardSectionTitle, DateField, SelectField, TextField } from 'components/ui';

import { useFetch } from 'func';
import { Roles } from 'types';
import { CharacterContext, UserContext } from 'contexts';
import { STATIC_URL, TEXT_FIELD_BIG, THESTRAL_URL } from 'const';

type Props = {
  readonly: boolean
}

export function General(props: Readonly<Props>) : ReactNode {
  const {char, setChar} = useContext(CharacterContext);
  const {user} = useContext(UserContext);
  const auth = useAuth();
  const [uploadPortrait, setUploadPortrait] = useState(null);
  const portraitUrl = char?.imagePath ? STATIC_URL + '/uploads/' + char?.imagePath : NoPortrait;
  const [showHint, setShowHint] = useState(0);

  const breeds = useFetch<string[]>(`${THESTRAL_URL}/breeds`);

  function hasAnyRole(allowed: Array<string>) {
      if (auth.isAuthenticated) {
      const roles: Roles = auth.user.profile.roles as Roles;
      return roles?.length > 0 && roles?.some(r => allowed.includes(r));
      }
      return false;
  }

  useEffect(() => {
    setShowHint(0);
  }, []);

  const changeSelectField = (event: SelectChangeEvent, key: string) => {
    const value = event.target.value;
    const newChar = {...char, [key]: value};
    setChar(newChar);
  };

  const selectFile = (event: ChangeEvent<HTMLInputElement>) => {
    setShowHint(1);
    setUploadPortrait(event.target.files[0]);
    setChar({...char, newPortrait: event.target.files[0]});
  };

  return (
    <Box
      sx={{
        marginTop: 0,
        marginLeft: 0,
      }}
    >
      <CardSectionTitle id="Generic.General" />
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="char.name"
                readonly={props.readonly}
                defaultValue={char.name}
                label={t('Components.PlayerCharacters.Name')}
                onChange={(event) => setChar({...char, name: event.target.value})}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                readonly={props.readonly}
                id="char.playerName"
                defaultValue="Test"
                label={t('Components.PlayerCharacters.PlayerName')}
              />
            </Grid>

            <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
              <TextField
                readonly={props.readonly}
                id="char.nickname"
                defaultValue={char.nickname}
                label={t('Generic.Nickname')}
                onChange={(event) => setChar({...char, nickname: event.target.value})}
              />
            </Grid>
            <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
              {breeds &&
              <SelectField
                  id="breed"
                  readonly={props.readonly}
                  label={t('Components.PlayerCharacters.Breed')}
                  value={[char?.breed]}
                  list={breeds.data}
                  i18nKey="Enum.Breed"
                  onChange={(event) => changeSelectField(event, 'breed')}
              />
              }
            </Grid>

            <Grid item xs={6} sx={{alignContent: 'flex-end'}}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <DateField
                  label={t('Components.PlayerCharacters.DateOfBirth')}
                  value={char?.dateOfBirth}
                  onChange={(newValue) =>  newValue && setChar({...char, dateOfBirth: newValue.toISODate()})}
                  readonly={props.readonly}
                  maxDate={'1999-12-31'}
                  minDate={'0800-01-01'}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <TextField
                readonly={props.readonly}
                id="char.npcType"
                defaultValue={t('Enum.NpcType.' + char.npcType)}
                label={t('Components.NonPlayerCharacters.Type')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item xs={4}
          sx={{
            '& :hover': {backgroundColor: 'transparent'},
            '& .MuiButton-root:hover': {
              backgroundColor: 'secondary.main',
            }
          }}>
          <Button
            component="label"
            sx={{
              width: '100%',
              border: '1px solid rgba(255, 255, 255, 0.7)',
              backgroundColor: 'transparent'
            }}
            text={
              <>
                <Box
                  sx={{
                    backgroundSize: 'cover',
                    width: '350px',
                    height: '350px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    contain: 'content'
                  }}
                >
                  {uploadPortrait && (
                    <img src={URL.createObjectURL(uploadPortrait)} alt="test" style={{height: '98%'}}/>
                  )}
                  {!uploadPortrait && (
                    <img src={portraitUrl} alt="test" style={{height: '98%'}}/>
                  )}
                </Box>
                <br />
                <input type="file" accept=".png" hidden onChange={selectFile} />
              </>
            }
          />

          <Typography component="p" sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'rgba(255, 82, 82, '+ showHint + ')'
          }}>
            {t('Generic.PortraitNotYetSaved')}
          </Typography>
        </Grid>

        {(hasAnyRole(['GAME_MASTER_MAIN']) || char?.playerName == user?.profile?.nickname) && 
        <>
            <Grid item xs={6}>
                <TextField
                    readonly={props.readonly}
                    id="char.description"
                    defaultValue={char?.description}
                    multiline
                    label={t('Components.PlayerCharacters.Description')}
                    rows={10}
                    onChange={(event) => setChar({...char, description: event.target.value})}
                    helperText={`${char?.description?.length ?? 0}/${TEXT_FIELD_BIG}`}
                    error={char?.description?.length > TEXT_FIELD_BIG}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    readonly={props.readonly}
                    id="char.secretDescription"
                    defaultValue={char?.secretDescription}
                    multiline
                    label={t('Components.PlayerCharacters.SecretDescription')}
                    rows={10}
                    onChange={(event) => setChar({...char, secretDescription: event.target.value})}
                    helperText={`${char?.secretDescription?.length ?? 0}/${TEXT_FIELD_BIG}`}
                    error={char?.secretDescription?.length > TEXT_FIELD_BIG}
                />
            </Grid>
        </>
        }
        {!hasAnyRole(['GAME_MASTER_MAIN']) && char?.playerName != user?.profile?.nickname &&
        <Grid item xs={12}>
            <TextField
                readonly={props.readonly}
                id="char.description"
                defaultValue={char?.description}
                multiline
                label={t('Components.PlayerCharacters.Description')}
                rows={10}
                onChange={(event) => setChar({...char, description: event.target.value})}
                helperText={`${char?.description?.length ?? 0}/${TEXT_FIELD_BIG}`}
                error={char?.description?.length > TEXT_FIELD_BIG}
            />
        </Grid>
        }
      </Grid>
    </Box>
  );
}
