import { useContext, ReactNode } from 'react';
import { SelectChangeEvent, Box, Grid } from '@mui/material';
import { t } from 'i18next';

import { CardSectionTitle, SelectField } from 'components/ui';

import { useFetch } from 'func';
import { CharacterContext } from 'contexts';
import { THESTRAL_URL } from 'const';

type Props = {
    readonly: boolean
}

export function WarTime(props: Readonly<Props>) : ReactNode {
    const {char, setChar} = useContext(CharacterContext);
    const schools = useFetch<string[]>(`${THESTRAL_URL}/schools`);

    const changeSelectField = (event: SelectChangeEvent, key: string) => {
      const value = event.target.value;
      const newChar = {...char, [key]: value};
      setChar(newChar);
    };

    return (
        <Box
            sx={{
                marginTop: 0,
                marginLeft: 0,
                paddingBottom: '30px'
            }}
        >
            <CardSectionTitle id="Generic.WarTime" />
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={6}>
                      {schools && 
                      <SelectField
                        readonly={props.readonly}
                        id="schoolBeforeWar"
                        label={t('Components.PlayerCharacters.SchoolBeforeWar')}
                        list={schools.data}
                        value={[char?.schoolBeforeWar]}
                        i18nKey="Enum.School"
                        onChange={(event) => changeSelectField(event, 'schoolBeforeWar')}
                      />
                      }
                </Grid>
                <Grid item xs={6}>
                      {schools && 
                      <SelectField
                        readonly={props.readonly}
                        id="schoolWhileWar"
                        label={t('Components.PlayerCharacters.SchoolWhileWar')}
                        list={schools.data}
                        value={[char?.schoolWhileWar]}
                        i18nKey="Enum.School"
                        onChange={(event) => changeSelectField(event, 'schoolWhileWar')}
                      />
                      }
                </Grid>
            </Grid>
        </Box>
    );
}
