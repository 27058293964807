import { useState, useEffect } from 'react';
import { Box} from '@mui/material';
import { Event, Groups, Person, School } from '@mui/icons-material';
import { t } from 'i18next';
import { useAuth } from 'react-oidc-context';

import { Roles } from 'types';
import { AdminCalendar, AdminClubs, AdminEvents, AdminPanel, AdminCharacters, AdminUserList, Headline } from 'components/thestral';
import { Dialog } from 'components/ui';
import { CalendarMonth } from 'mdi-material-ui';
import { useNavigate } from 'react-router-dom';

export function Admin() {
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<string>('');
  const title = t('Components.Admin.Title');
  const breadcrumbs = [
    {href: '/', title: t('Generic.Start')},
  ];
  const auth = useAuth();
  const nav = useNavigate();

  useEffect(() => {
    if (!isAdmin(['ADMIN', 'ORGA'])) {
      nav('/');
    }
  }, []);

  function isAdmin(allowed: Array<string>) {
      if (auth.isAuthenticated) {
      const roles: Roles = auth.user.profile.roles as Roles;
      return roles?.length > 0 && roles?.some(r => allowed.includes(r));
      }
      return false;
  }

  const openDialog = (page) => {
    setOpen(true);
    setPage(page);
  };

  const content = () => {
    switch (page) {
      case 'Users': return (<AdminUserList />);
      case 'Character': return (<AdminCharacters />);
      case 'Events': return (<AdminEvents />);
      case 'Clubs': return (<AdminClubs />);
      case 'Calendar': return (<AdminCalendar />);
    }
    return (<></>);
  };

  return (
    <Box className="content" sx={{marginTop: '50px'}}>
      <Headline title={title} breadcrumbs={breadcrumbs} />
      
      <Box 
        sx={{
          justifyContent: 'center', 
          alignItems: 'center', 
          display: 'flex',
        }}>
        <AdminPanel 
          onClick={() => openDialog('Users')}
          icon={<Person sx={{ fontSize: 90 }} />}
          title={t('Components.Admin.Users.Title')}
        /> 
        <AdminPanel 
          onClick={() => openDialog('Character')}
          icon={<Groups sx={{ fontSize: 90 }} />}
          title={t('Components.Admin.Character.Title')}
        /> 
        <AdminPanel 
          onClick={() => openDialog('Events')}
          icon={<Event sx={{ fontSize: 90 }} />}
          title={t('Components.Admin.Events.Title')}
        /> 
        <AdminPanel 
          onClick={() => openDialog('Clubs')}
          icon={<School sx={{ fontSize: 90 }} />}
          title={t('Components.Admin.Clubs.Title')}
        /> 
        <AdminPanel 
          onClick={() => openDialog('Calendar')}
          icon={<CalendarMonth sx={{ fontSize: 90 }} />}
          title={t('Components.Admin.Calendar.Title')}
        /> 
      </Box>

      {page &&
        <Dialog 
          open={open} 
          onClose={() => setOpen(false)}
          title={t('Components.Admin.' + page + '.Title')}
          content={content()}
          fullScreen
          noActions
        />
      }
    </Box>
  );
}