import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
  title: string,
  content: ReactNode
}

export function Card(props: Readonly<Props>): ReactNode {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#dedede',
        borderRadius: '4px',
        padding: '20px',
        height: '450px'
      }}
    >
      <Typography variant="h4" component="div" sx={{ color: 'black'}}>
        { props.title }
      </Typography>
      { props.content }
    </Box>
  );
}
