import { ReactNode } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';

import { GridHeadline } from 'components/thestral';
import { ImageRights } from './ImageRights';
import { DataProtection } from './DataProtection/DataProtection';
import { FormikProps, FormikValues } from 'formik';

type Props = {
  formik: FormikProps<FormikValues>
}

export function DataProtectionData(props: Readonly<Props>): ReactNode {
  return (
    <Grid item xs={6} sx={{'& .MuiSvgIcon-root': {color: 'primary.main'}}}>
      <GridHeadline title={t('Components.Profile.DataProtectionData.Title')} />

      <DataProtection formik={props.formik} />
      <ImageRights formik={props.formik} />
    </Grid>
  );
}
