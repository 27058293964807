import { ReactNode } from 'react';
import { Grid, Box } from '@mui/material';

import { Groups, AccountBalance, Diversity3, DryCleaning, Public  } from '@mui/icons-material';

import { SectionLinkBox } from './SectionLinkBox';
import { t } from 'i18next';

export function Sections() : ReactNode {
  return (
    <Box
      sx={{
        height: 144,
        margin: '50px 0 50px 0',
        backgroundColor: 'secondary.main',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        left: 0,
        right: 0
      }} 
    >
      <Grid 
        container 
        spacing={2} 
        direction="row" 
        justifySelf="space-between" 
        alignItems="stretch" 
        sx={{
          marginTop: 0,
          height: '60%',
          '& .MuiGrid-item:hover': {
            '.child': {
              color: 'primary.main',
              transform: 'scale(1.1)',
            },
            cursor: 'pointer',
          },
          '.child': {
            transition: '.5s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s',
          },
          '.child::after': {
            transition: 'opacity 0.1s cubic-bezier(0.4, 0, 0.6, 1)'
          }
        }}
      >
        <Grid item xs={2} />
        <SectionLinkBox icon={Groups} text={t('Components.LoggedIn.Sections.Characters')} to="/characters" />
        <SectionLinkBox icon={AccountBalance} text={t('Components.LoggedIn.Sections.IT')} to="/rules/it" />
        <SectionLinkBox icon={Public} text={t('Components.LoggedIn.Sections.World')} to="/rules/world-building" />
        <SectionLinkBox icon={DryCleaning} text={t('Components.LoggedIn.Sections.Drapery')} to="/rules/drapery" />
        <SectionLinkBox icon={Diversity3} text={t('Components.LoggedIn.Sections.Conduct')} to="/rules/code-of-conduct" noBorder />
        <Grid item xs={2} />
      </Grid>
    </Box>
  );
}