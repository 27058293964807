import { Box } from '@mui/material';
import Wallpaper from 'assets/images/thestral_bg.png';

export function Background() {
  return (
    <>
      <Box 
        component="img"
        sx={{
          position: 'fixed',
          left: '0',
          top: '0',
          bottom: '0',
          right: '0',
          zIndex: '-1'
        }}
        src={Wallpaper}
      /> 
    </>
  );
}