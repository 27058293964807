import { ReactNode, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';

import { CardCircularProgress } from 'components/thestral';

import { EventCardItem } from './EventCardItem';

import { useFetch } from 'func';
import { Event } from 'types';
import { EVENTS_URL } from 'const';
import { DateTime } from 'luxon';

export function EventCard(): ReactNode {
  const {data, loading} = useFetch<Event[]>(EVENTS_URL);
  const [filtered, setFiltered] = useState<Event[]>();

  useEffect(() => {
    if (data) {
      const now = DateTime.now();
      setFiltered(data.filter((event) => DateTime.fromISO(event.start) >= now));
    }
  }, [data]);

  return (
      <>
        {loading ? 
          <CardCircularProgress />
          :
          <Carousel autoPlay={false} animation="slide" height="336px">
              {filtered?.map((item: Event) => <EventCardItem key={item.name} item={item} />)}
          </Carousel>
        }
      </>
  );
}