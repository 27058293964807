import { useAuth } from 'react-oidc-context';
import { Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { Button } from 'components/ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function LandingPage() {
  const nav = useNavigate();
  const auth = useAuth(); 

  useEffect(() => {
    if (!auth.isLoading && auth.isAuthenticated) {
      nav('/');
    }
  }, [auth.isAuthenticated, auth.isLoading]);

  return (
    <Box
      sx={{ 
        zIndex: 2,
        position: 'relative',
        textAlign: 'center'
      }} 
    >
      <Typography 
        variant="h1" 
        sx={{ 
          fontSize: '3.5rem', 
          fontWeight: '700', 
        }}
      >
        <Trans i18nKey="Generic.Title" />
      </Typography>
      <Button 
        className="landing-btn" 
        color="secondary"
        onClick={() => auth.signinRedirect()}
        sx={{
          width: '260px',
          position: 'relative',
          top: '75px',
          fontSize: '25px',
          letterSpacing: '1px',
          padding: '0px'
        }}
        text={<Trans i18nKey="Generic.Login" />}
      />
        
    </Box>
  );
}