import { ReactNode } from 'react';
import { t } from 'i18next';

import { RulesTab, RulesTabContext } from 'components/thestral';

import { DraperyCheckTab } from './DraperyCheckTab';

import { RuleComponent } from 'types';

export function Drapery(): ReactNode {
  const title = t('Components.Rules.Drapery.Title');
  const breadcrumbs = [
    {href: '/', title: t('Generic.Start')},
    {href: '/rules', title: t('Components.Rules.Title')}
  ];

  const items = [
    {name: t('Components.Rules.Drapery.DraperyCheck.Title'), component: <DraperyCheckTab />},
    {name: t('Components.Rules.Drapery.General'), component: <RulesTab slug="general" ruleType='Drapery'/>},
    {name: t('Components.Rules.Drapery.Schooluniform'), component: <RulesTab slug="schooluniform" ruleType="Drapery" />},
    {name: t('Components.Rules.Drapery.Misc'), component: <RulesTab slug="misc" ruleType="Drapery" />},
    {name: t('Components.Rules.Drapery.Freetime'), component: <RulesTab slug="freetime" ruleType="Drapery" />},
    {name: t('Components.Rules.Drapery.Sports'), component: <RulesTab slug="sports" ruleType="Drapery" />},
    {name: t('Components.Rules.Drapery.Quidditch'), component: <RulesTab slug="quidditch" ruleType="Drapery" />},
    {name: t('Components.Rules.Drapery.Wigs'), component: <RulesTab slug="wigs" ruleType="Drapery" />},
  ];

  return (
    <RulesTabContext 
      title={title}
      items={items as RuleComponent[]}
      breadcrumbs={breadcrumbs}
    />
  );
}