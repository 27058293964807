import { useContext, ReactNode } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';

import { CharacterContext } from 'contexts';
import { TextField } from 'components/ui';

type Props = {
  readonly: boolean
}

export function PortraitAuthor(props: Readonly<Props>) : ReactNode {
  const {char, setChar} = useContext(CharacterContext);

  return (
    <Grid item xs={12}>
      <TextField 
        id="char.author"
        readonly={props.readonly}          
        defaultValue={char.author}
        label={t('Components.NonPlayerCharacters.Author')}
        onChange={(event) => setChar({...char, author: event.target.value})}
      />
    </Grid>
  );
}