import { Box, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { GridHeadline, ThestralCheckbox } from 'components/thestral';
import { DateField, LinkText, TextField } from 'components/ui';
import { FormikProps, FormikValues } from 'formik/dist/types';

type Props = {
  formik: FormikProps<FormikValues>
}

export function RegisterForm(props: Readonly<Props>) {
  const formik = props.formik;

  return (
    <Box sx={{marginTop: '50px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GridHeadline title={t('Components.Register.Address')} />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="firstname"
            label={t('Components.Profile.PersonalData.LegalData.FirstName')}
            value={formik.values.firstname}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.firstname)}
            helperText={formik.errors.firstname as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="lastname"
            label={t('Components.Profile.PersonalData.LegalData.LastName')}
            value={formik.values.lastname}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.lastname)}
            helperText={formik.errors.lastname as string}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField 
            id="streetName"
            label={t('Components.Profile.PersonalData.LegalData.StreetName')}
            value={formik.values.streetName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.streetName)}
            helperText={formik.errors.streetName as string}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField 
            id="streetNumber"
            label={t('Components.Profile.PersonalData.LegalData.StreetNumber')}
            value={formik.values.streetNumber}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.streetNumber)}
            helperText={formik.errors.streetNumber as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="addressOptional"
            label={t('Components.Profile.PersonalData.LegalData.AddressOptional')}
            value={formik.values.addressOptional}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.addressOptional)}
            helperText={formik.errors.addressOptional as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="zipCode"
            label={t('Components.Profile.PersonalData.LegalData.ZipCode')}
            value={formik.values.zipCode}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.zipCode)}
            helperText={formik.errors.zipCode as string}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="city"
            label={t('Components.Profile.PersonalData.LegalData.City')}
            value={formik.values.city}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.city)}
            helperText={formik.errors.city as string}
          />
        </Grid>

        <Grid item xs={12}>
          <GridHeadline title={t('Components.Register.Other')} />
        </Grid>
        <Grid item xs={6}>
          <DateField 
            label={t('Generic.DateOfBirth')} 
            minDate="1970-01-01" 
            disableFuture 
            value={formik.values.dateOfBirth}
            error={Boolean(formik.errors.dateOfBirth)}
            helperText={formik.errors.dateOfBirth as string}
            onChange={(value) => formik.setFieldValue('dateOfBirth', value.toISODate())}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="nickname"
            label={t('Generic.Nickname')}
            value={formik.values.nickname}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.nickname)}
            helperText={formik.errors.nickname as string}
          />
        </Grid>

        <Grid item xs={12}>
          <GridHeadline title={t('Components.Register.Password')} />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="password"
            label={t('Components.Register.Password')}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.password)}
            helperText={formik.errors.password as string}
            type="password"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            id="passwordConfirm"
            label={t('Components.Register.PasswordRepeat')}
            value={formik.values.passwordConfirm}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.passwordConfirm)}
            helperText={formik.errors.passwordConfirm as string}
            type="password"
          />
        </Grid>

        <Grid item xs={6}>
          <GridHeadline title={t('Generic.DataProtection')} />

          <Typography variant="caption" component="div">
            <Trans 
              i18nKey="Components.Register.DataProtection.Text"
              components={{
                url: <LinkText to='/privacy' target="_blank" />
              }} 
            />
          </Typography> 
        </Grid>

        <Grid item xs={6}>
          <GridHeadline title={t('Generic.CodeOfConduct')} />

          <Typography variant="caption" component="div">
            <Trans 
              i18nKey="Components.Register.CodeOfConduct.Text"
              components={{
                url: <LinkText to='/rules/code-of-conduct' target="_blank" />
              }} 
            />
          </Typography> 
        </Grid>

        <Grid item xs={6}>
          <ThestralCheckbox 
            id="legal"
            text={t('Components.Register.DataProtection.Checkbox')} 
            checked={formik.values.legal}
            onChange={(event) => formik.setFieldValue('legal', event.target.checked)}
          />
          {Boolean(formik.errors.legal) &&
            <Typography color="error" component="div" variant="caption">
              {typeof formik.errors.legal === 'string' && formik.errors.legal}
            </Typography>          
          }
        </Grid>
        <Grid item xs={6}>
          <ThestralCheckbox 
            id="codeOfConductAccepted"
            text={t('Components.Register.CodeOfConduct.Checkbox')} 
            checked={formik.values.codeOfConductAccepted}
            onChange={(event) => formik.setFieldValue('codeOfConductAccepted', event.target.checked)}
          />
          {Boolean(formik.errors.codeOfConductAccepted) &&
            <Typography color="error" component="div" variant="caption">
              {typeof formik.errors.codeOfConductAccepted === 'string' && formik.errors.codeOfConductAccepted}
            </Typography>          
          }
        </Grid>
      </Grid>
    </Box>
  );
}
