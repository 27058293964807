import { Grid } from '@mui/material';
import { t } from 'i18next';

import { Button, DateField, Dialog, TextField } from 'components/ui';
import { useState } from 'react';
import { EVENTS_URL, HTTP_METHOD } from 'const';
import { useFetchFromBackend } from 'func';

type Props = {
  open: boolean,
  onClose: () => void,
  triggerRefetch: () => void
  showSnackbar?: (msg, severity) => void
}

export function NewEventDialog(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();

  const [loading, setLoading] = useState<boolean>();
  const [start, setStart] = useState<string>();
  const [name, setName] = useState<string>();
  const [place, setPlace] = useState<string>();
  const [end, setEnd] = useState<string>();

  const onSave = () => {
    setLoading(true);
    const body = JSON.stringify({name, start, end, place});

    fetchFromBackend(EVENTS_URL, {
      method: HTTP_METHOD.POST,
      body
    }).then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok ? t('Generic.Successful') : `${t('Generic.Failed')} (${response.status})`;

          props.showSnackbar(`${t('Generic.ApprovalChange')} ${result}`, severity);
          props.triggerRefetch();
          props.onClose();
        }
      )
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const actions = () => {
    if (loading) return <></>;

    return (
      <Button 
        onClick={onSave}
        color="success" 
        sx={{ marginLeft: '5px' }} 
        text={t('Components.Admin.Events.Save')} 
        disabled={!name || !start || !end || !place}
      />
    );
  };

  const content = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField 
            label={t('Generic.Name')}
            onChange={(event) => setName(event.target.value)}
          /> 
        </Grid>

        <Grid item xs={6}>
          <TextField 
            label={t('Components.Events.Location')}
            onChange={(event) => setPlace(event.target.value)}
          /> 
        </Grid>

        <Grid item xs={6}>
          <DateField 
            label={t('Components.Events.Start')} 
            value={start}
            onChange={(newDate) => setStart(newDate.toISODate())}
            disablePast
            maxDate={end}
          />
        </Grid>

        <Grid item xs={6}>
          <DateField 
            label={t('Components.Events.End')} 
            value={end}
            onChange={(newDate) => setEnd(newDate.toISODate())}
            disablePast
            minDate={start}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog 
      open={props.open}
      onClose={props.onClose}
      title={t('Components.Admin.Events.New')}
      actions={actions()}
      content={content()}
    />
  );
}
