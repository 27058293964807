import { ReactNode, useEffect, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import { t } from 'i18next';

import { ThestralDataGrid, ThestralDataGridHeadline } from 'components/thestral';

import { Character, NpcType } from 'types';
import { CHARACTERS_URL, EMPTY } from 'const';
import { useFetch } from 'func';

type Props = {
  type: NpcType,
  onRowClick: (any) => void,
  sx: SxProps
}

export function NpcList(props: Readonly<Props>): ReactNode {
  const { data, loading } = useFetch<Character[]>(`${CHARACTERS_URL}/npcs?npcType=${props.type}`);
  const [chars, setChars] = useState<Character[]>();

  useEffect(() => {
    if (data) {
      setChars(data);
    }
  }, [data]);

  const onRowClick = (elem) => {
    props.onRowClick(elem.row);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ThestralDataGridHeadline 
        headline={t('Enum.NpcType.' + props.type)}
      />
      <ThestralDataGrid
        rows={chars || EMPTY}
        onRowClick={(elem) => onRowClick(elem)}
        sx={props.sx}
        columns={
          [
            {field: 'name', headerName: t('Components.PlayerCharacters.Name') , width: 300, sortable: true},
            {
              field: 'playerName',
              headerName: t('Components.PlayerCharacters.PlayerName'), 
              sortable: true, 
              flex: 1, 
              align: 'right', 
              headerAlign: 'right'
            }
          ]
        } 
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5}
          }
        }}
        pageSizeOptions={[5, 10]}
        loading={loading}
      /> 
    </Box>
  );
}